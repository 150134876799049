import { Button, CardContent, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, email, required, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { CheckboxGroupInput, TextInput } from 'react-admin';
export const LoginForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();

  const submit = (values: any) => {
    setLoading(true);
    login(values)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
            },
          },
        );
      });
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="email"
          label="Email"
          type="email"
          placeholder="e-mail address"
          validate={[required(), email('Please enter your e-mail address')]}
          fullWidth
        />
        <TextInput
          source="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          validate={required()}
          fullWidth
        />

        <CheckboxGroupInput source="role" choices={[{ id: 'admin', name: 'Admin' }]} />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} /> : 'Login'}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export default LoginForm;
