/* eslint react/jsx-key: off */
import PeopleIcon from '@mui/icons-material/People';
import { Theme, useMediaQuery } from '@mui/material';
import { ReactElement } from 'react';
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleList,
  TextField,
  usePermissions,
} from 'react-admin';

import UserEditEmbedded from './UserEditEmbedded';
export const UserIcon = PeopleIcon;

// function that returns an array of filters in react admin
const getUserFilters = (): ReactElement[] => {
  const filters = [
    <SearchInput source="display_name,email" alwaysOn />,
    <ReferenceInput source="role" reference="role" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
    // permissions === 'master' ? <TextInput source="role" /> : null,
  ];
  return filters.filter((filter) => filter !== null) as ReactElement[];
};

const UserBulkActionButtons = (props: any) => <BulkDeleteWithConfirmButton {...props} />;

const UserList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      filters={getUserFilters()}
      // filterDefaultValues={{ role: 'user' }}
      sort={{ field: 'display_name', order: 'ASC' }}
    >
      {useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? (
        <SimpleList
          primaryText={(record) => record.display_name}
          secondaryText={(record) => (permissions === 'master' ? record.role : null)}
        />
      ) : (
        <Datagrid
          expand={<UserEditEmbedded />}
          bulkActionButtons={<UserBulkActionButtons />}
          rowClick="expand"
          optimized
        >
          <TextField source="id" />
          <TextField source="display_name" />
          <TextField source="email" />
          {permissions === 'master' && <TextField source="role" />}
        </Datagrid>
      )}
    </List>
  );
};

export default UserList;
