/* eslint react/jsx-key: off */
import * as React from 'react';
import {
  CloneButton,
  Edit,
  email,
  required,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
} from 'react-admin';

import Aside from './Aside';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const GuestEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const EditActions = () => (
  <TopToolbar>
    <CloneButton className="button-clone" />
    <ShowButton />
  </TopToolbar>
);

const GuestEditForm = () => {
  return (
    <SimpleForm toolbar={<GuestEditToolbar />}>
      <TextInput source="name_jp" validate={required()} />
      <TextInput source="name_en" validate={required()} />
      <TextInput source="title" validate={required()} />
      <TextInput source="company_name" validate={required()} />
      <TextInput source="company_short_name" />
      <TextInput source="company_address" validate={required()} />
      <TextInput source="work_phone" validate={required()} />
      <TextInput source="mobile" />
      <TextInput source="fax" />
      <TextInput source="email" validate={[required(), email()]} />
      <TextInput source="website" />
      <TextInput source="others" />
    </SimpleForm>
  );
};
const GuestEdit = () => {
  return (
    <Edit aside={<Aside />} actions={<EditActions />} mutationMode="pessimistic">
      <GuestEditForm />
    </Edit>
  );
};

export default GuestEdit;
