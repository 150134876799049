/* eslint react/jsx-key: off */
import PeopleIcon from '@mui/icons-material/People';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import Aside from './Aside';
import GuestViewEmbedded from './GuestViewEmbedded';
export const GuestIcon = PeopleIcon;

const GuestList = () => {
  return (
    <List
      queryOptions={{ refetchInterval: 5000 }}
      // filterDefaultValues={{ role: 'guest' }}
      sort={{ field: 'created_at', order: 'DESC' }}
      aside={<Aside />}
    >
      <Datagrid expand={<GuestViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField label="Name" source="name_jp" />
        <TextField label="Position" source="title" />
        <TextField source="company_name" />
        <TextField source="work_phone" />
        <TextField source="mobile" />

        <DateField source="updated_at" showTime locales="ja-JP" />
      </Datagrid>
    </List>
  );
};

export default GuestList;
