/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  email,
  Identifier,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const UserEditEmbedded = ({ id }: { id?: Identifier }) => {
  const { permissions } = usePermissions();
  return (
    <Edit title=" " id={id}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="first_name" validate={required()} disabled={permissions != 'master'} />
        <TextInput source="last_name" validate={required()} disabled={permissions != 'master'} />
        <TextInput source="email" validate={[required(), email()]} disabled={permissions != 'master'} />
        {permissions === 'ediop' && <TextInput source="password" />}
        {permissions === 'master' && (
          <ReferenceInput source="role" reference="role">
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        )}
        {permissions === 'master' && <BooleanInput label="Disabled" source="deleted" />}
      </SimpleForm>
    </Edit>
  );
};

UserEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default UserEditEmbedded;
