/* eslint react/jsx-key: off */
import { Create, required, SimpleForm, TextInput } from 'react-admin';

const ClientCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="name" autoFocus validate={[required()]} />
        <TextInput source="chatwork_api_token" />
        <TextInput source="chatwork_room" />
        <TextInput source="slack_token" />
        <TextInput source="slack_channel_id" />
      </SimpleForm>
    </Create>
  );
};

export default ClientCreate;
