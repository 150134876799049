/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { Edit, Identifier, required, SimpleForm, TextInput } from 'react-admin';

const ClientEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    /* Passing " " as title disables the custom title */

    <Edit title=" " id={id}>
      <SimpleForm>
        <TextInput source="name" autoFocus validate={[required()]} />
        <TextInput source="chatwork_api_token" />
        <TextInput source="chatwork_room" />
        <TextInput source="slack_token" />
        <TextInput source="slack_channel_id" />
      </SimpleForm>
    </Edit>
  );
};

ClientEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default ClientEditEmbedded;
