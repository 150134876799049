import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
const httpLink = createHttpLink({
  uri: baseApiUrl,
});

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const auth = localStorage.getItem('auth');
  let token = '';
  if (auth) {
    token = JSON.parse(auth).token;
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
