import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FieldProps, useRecordContext } from 'react-admin';

const GET_SIGNED_URL = gql`
  query GetSignedUrl($key: String!) {
    get_signed_url(key: $key) {
      url
    }
  }
`;

interface SignedUrlResponse {
  get_signed_url: {
    url: string;
  };
}

interface RecordType {
  [key: string]: any;
}

interface CloudFrontImageFieldProps extends FieldProps {
  source: string;
}

const CloudFrontImageField: React.FC<CloudFrontImageFieldProps> = ({ source }) => {
  const record = useRecordContext<RecordType>();
  const [signedUrl, setSignedUrl] = useState<string>('');

  const { data, error } = useQuery<SignedUrlResponse>(GET_SIGNED_URL, {
    variables: { key: record?.[source] },
    skip: !record || !(source in record),
  });

  useEffect(() => {
    if (data) {
      setSignedUrl(data.get_signed_url.url);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('Error fetching signed URL:', error);
    }
  }, [error]);

  if (!record || !(source in record)) return null;

  const handleImageClick = () => {
    if (signedUrl) {
      window.open(signedUrl, '_blank');
    }
  };

  return (
    <img
      src={signedUrl || '#'}
      alt={record[source]}
      title={record[source]}
      style={{ maxWidth: '100%', maxHeight: '200px', cursor: 'pointer' }}
      onClick={handleImageClick}
    />
  );
};

export default CloudFrontImageField;
