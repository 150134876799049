import DefaultIcon from '@mui/icons-material/ViewList';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useCreatePath, useGetResourceLabel } from 'ra-core';
import { LayoutProps } from 'ra-ui-materialui';
import { createElement } from 'react';
import {
  AppBar,
  AppBarProps,
  Layout,
  Menu,
  MenuItemLink,
  UserMenu,
  useLogout,
  usePermissions,
  useResourceDefinitions,
} from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Admin_Role_Enum, User_Role_Enum } from 'src/utils/enum';
import BackButton from './BackButton';

export const LayoutWrapper = (props: LayoutProps): JSX.Element => {
  return (
    <>
      <Layout
        sx={{ '& .RaLayout-content': { paddingRight: 4, paddingLeft: 4 } }}
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <AppBar {...props} color="secondary" position={'fixed'} elevation={1} userMenu={<CustomUserMenu />}>
      <BackButton />
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />

      {isLargeEnough && (
        <Box
          sx={{
            flex: 1,
            textAlign: 'center',
            '& svg': {
              width: '112px',
              height: '20px',
              '@media (min-width:600px)': {
                width: '160px',
                height: '17.5px',
              },
            },
          }}
        >
          <img style={{ height: '36px', width: 'auto' }} src={process.env.PUBLIC_URL + '/logo.svg'} />
        </Box>
      )}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <div></div>
  </UserMenu>
);

const activeMenu = {
  admin: ['*'],
  support: ['guest', 'client', 'user'],
  owner: ['guest', 'client'],
  member: ['guest'],
};
const CustomMenu = () => {
  const resources = useResourceDefinitions();
  const { permissions }: { permissions: string } = usePermissions();
  const menus =
    permissions === Admin_Role_Enum.Admin
      ? activeMenu.admin
      : permissions === Admin_Role_Enum.Support
      ? activeMenu.support
      : permissions === User_Role_Enum.Owner
      ? activeMenu.owner
      : activeMenu.member;
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const logout = useLogout();
  const handleLogoutClick = async () => {
    try {
      await logout();
      window.location.href = '/login';
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Menu>
      {Object.keys(resources)
        .filter((name) => resources[name].hasList && (menus[0] === '*' || menus.includes(name)))
        .map((name) => (
          <MenuItemLink
            key={name}
            to={createPath({
              resource: name,
              type: 'list',
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name)}
            leftIcon={resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />}
          />
        ))}
      <MenuItemLink to={'#'} primaryText={'Logout'} onClick={handleLogoutClick} leftIcon={<DefaultIcon />} />
    </Menu>
  );
};
