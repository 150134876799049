import PeopleIcon from '@mui/icons-material/People';
import GuestCreate from './GuestCreate';
import GuestEdit from './GuestEdit';
import GuestList from './GuestList';
import GuestViewEmbedded from './GuestViewEmbedded';

export default {
  list: GuestList,
  create: GuestCreate,
  edit: GuestEdit,
  show: GuestViewEmbedded,
  icon: PeopleIcon,
  recordRepresentation: (record: any) => `${record.name_jp} ${record.name_en}`,
};
