/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { Edit, Identifier, required, SimpleForm, TextInput } from 'react-admin';

const UserEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    /* Passing " " as title disables the custom title */

    <Edit title=" " id={id}>
      <SimpleForm>
        <TextInput source="key" validate={required()} />
        <TextInput source="value" validate={required()} multiline fullWidth />
        <TextInput source="description" fullWidth multiline />
      </SimpleForm>
    </Edit>
  );
};

UserEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default UserEditEmbedded;
