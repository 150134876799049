import { ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createBrowserHistory as createHistory } from 'history';
import buildHasuraProvider from 'ra-data-hasura';
import React from 'react';
import { Admin, DataProvider, Resource } from 'react-admin';
import client from './apolloClient';
import { LayoutWrapper } from './components/layout-wrapper';
import { Login } from './pages';
import clients from './pages/clients';
import guests from './pages/guests';
import logs from './pages/logs';
import settings from './pages/settings';
import users from './pages/users';
import { authProvider } from './providers/auth.provider';
import i18nProvider from './providers/i18n.provider';
import { lightTheme } from './services/theme';
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';

const httpLink = createHttpLink({
  uri: baseApiUrl,
});

let headersAuth = {};
export const setAuthorizationHeader = (token: string) => {
  headersAuth = {
    authorization: `Bearer ${token}`,
  };
};

const App: React.FC = () => {
  const history = createHistory();
  const [dataProvider, setDataProvider] = React.useState<DataProvider | null>(null);
  const authLinkBuilder = () => {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...headersAuth,
        },
      };
    });
  };
  React.useEffect(() => {
    const buildDataProvider = async () => {
      let token = '';
      try {
        const auth = await authProvider.getIdentity();
        if (auth) {
          token = auth.token;
        }
      } catch (e) {
        token = '';
      }
      setAuthorizationHeader(token);
      const authLink = authLinkBuilder();
      const dataProviderHasura = await buildHasuraProvider({
        clientOptions: {
          link: authLink.concat(httpLink) as any,
        },
      });
      setDataProvider(() => dataProviderHasura);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <ApolloProvider client={client}>
      <Admin
        requireAuth={true}
        theme={lightTheme}
        layout={LayoutWrapper}
        loginPage={Login}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        history={history}
      >
        <Resource name="guest" {...guests} />
        <Resource name="client" {...clients} />
        <Resource name="user" {...users} />
        <Resource name="setting" {...settings} />
        <Resource name="log" {...logs} />
      </Admin>
    </ApolloProvider>
  );
};

export default App;
