export enum User_Role_Enum {
  /** Owner */
  Owner = 'OWNER',
  Member = 'MEMBER',
}

export enum Admin_Role_Enum {
  /** Owner */
  Admin = 'admin',
  Support = 'SUPPORT',
}

export const Admin_Roles = [Admin_Role_Enum.Admin, Admin_Role_Enum.Support];
export const User_Roles = [User_Role_Enum.Owner, User_Role_Enum.Member];
