import ClientsIcon from '@mui/icons-material/Settings';

import ClientCreate from './ClientCreate';
import ClientList from './ClientList';

export default {
  list: ClientList,
  create: ClientCreate,
  icon: ClientsIcon,
  recordRepresentation: (record: any) => `${record.name}`,
};
