/* eslint react/jsx-key: off */
import { EmailField, Identifier, Show, SimpleShowLayout, TextField, UrlField } from 'react-admin';
import CloudFrontImageField from 'src/components/CloudFrontImageField';

const GuestViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <TextField source="name_jp" />
      <TextField source="name_en" />
      <TextField source="title" />
      <TextField source="company_short_name" />
      <TextField source="company_name" />
      <TextField source="company_address" />
      <TextField source="work_phone" />
      <TextField source="fax" />
      <TextField source="mobile" />
      <EmailField source="email" />
      <UrlField source="website" />
      <TextField source="others" />
      <CloudFrontImageField label="Business Card" source="image" />
    </SimpleShowLayout>
  </Show>
);

export default GuestViewEmbedded;
