/* eslint react/jsx-key: off */
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

import ClientEditEmbedded from './ClientEditEmbedded';

const ClientList = () => {
  return (
    <List>
      <Datagrid expand={<ClientEditEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="chatwork_api_token" />
        <TextField source="chatwork_room" />
        <TextField source="slack_token" />
        <TextField source="slack_channel_id" />
        <ReferenceField source="updated_by" reference="user" />
        <DateField source="updated_at" showTime locales="ja-JP" />
      </Datagrid>
    </List>
  );
};

export default ClientList;
