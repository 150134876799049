/* eslint react/jsx-key: off */
import { DateField, EmailField, ReferenceField, Show, SimpleShowLayout, TextField, usePermissions } from 'react-admin';
import { Admin_Roles } from 'src/utils/enum';

const UserShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="display_name" />
        <EmailField source="email" />
        <ReferenceField source="updated_by" reference="user" />
        <DateField source="updated_at" showTime locales="ja-JP" />
        {Admin_Roles.includes(permissions) && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime locales="ja-JP" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
