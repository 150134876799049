/* eslint react/jsx-key: off */
import { Create, email, required, SimpleForm, TextInput } from 'react-admin';

import Aside from './Aside';

const GuestCreate = () => {
  const transform = (formData: any) => {
    formData.id = Math.floor(100000 + Math.random() * 900000);
    return formData;
  };

  return (
    <Create aside={<Aside />} transform={transform} redirect="list">
      <SimpleForm>
        <TextInput source="name_jp" validate={required()} />
        <TextInput source="name_en" validate={required()} />
        <TextInput source="title" validate={required()} />
        <TextInput source="company_name" validate={required()} />
        <TextInput source="company_short_name" />
        <TextInput source="company_address" validate={required()} />
        <TextInput source="work_phone" />
        <TextInput source="mobile" />
        <TextInput source="fax" />
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="website" />
        <TextInput source="others" />
      </SimpleForm>
    </Create>
  );
};

export default GuestCreate;
