import FlightLandIcon from '@mui/icons-material/FlightLand';
import { Card, CardContent } from '@mui/material';
import moment from 'moment';
import { FilterList, FilterListItem, FilterLiveSearch, SavedQueriesList } from 'react-admin';

const Aside = () => {
  return (
    <Card
      sx={{
        marginTop: '4em',
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch source="name_jp@_ilike,email,company_name,work_phone,mobile" />

        <SavedQueriesList />
        <FilterList label="Checked-In" icon={<FlightLandIcon />}>
          <FilterListItem
            label="Today"
            value={{
              'created_at@_gte': moment().startOf('day').format(),
              'created_at@_lte': moment().endOf('day').format(),
            }}
          />
          <FilterListItem
            label="Yesterday"
            value={{
              'created_at@_gte': moment().add(-1, 'days').startOf('day').format(),
              'created_at@_lte': moment().add(-1, 'days').endOf('day').format(),
            }}
          />
          <FilterListItem
            label="Last week"
            value={{
              'created_at@_gte': moment().subtract(1, 'weeks').startOf('week').add(1, 'days').format('YYYY-MM-DD'),
              'created_at@_lte': moment().startOf('week').format('YYYY-MM-DD'),
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
